/**
 * @file Anything that can run on every page.
 */

/**
 * Global functions.
 *
 * Runs component js and any global tasks.
 */
const vGlobal = () => {
	import('../components/header/header').then(({ default: vHeader }) => {
		vHeader();
	});
	import('../components/nav-trigger/nav-trigger').then(
		({ default: vNavTrigger }) => {
			vNavTrigger();
		}
	);
	import('../components/mobile-nav/mobile-nav').then(
		({ default: vMobileNav }) => {
			vMobileNav();
		}
	);
	import('../components/resources-ctas/resources-ctas').then(
		({ default: vResourcesCtas }) => {
			vResourcesCtas();
		}
	);

	const selects = document.querySelectorAll('select');
	[...selects].forEach((select) => {
		const selectParent = select.parentNode;
		if (selectParent.classList.contains('v-form__select')) {
			return;
		}

		const wrapper = document.createElement('span');
		const arrow = document.createElement('span');
		wrapper.classList.add('v-form__select');
		arrow.classList.add('v-form__select-arrow');
		selectParent.insertBefore(wrapper, select);
		wrapper.appendChild(select);
		wrapper.appendChild(arrow);
		select.classList.add('v-form__select-field');
	});

	/**
	 * Global Window Load.
	 *
	 * Runs polyfills on page load.
	 */
	async function onWindowLoad() {
		const body = document.querySelector('body');
		if (!body.classList.contains('admin-bar')) {
			const { listen } = await import('quicklink');

			listen({
				ignores: [
					(url) => {
						const cleanUrl = url.replace(/#.*/, '');
						return cleanUrl === window.location.href;
					},
					/.*\/wp-admin\/.*/,
				],
			});
		}
	}

	if (document.readyState === 'complete') {
		onWindowLoad();
	} else {
		window.addEventListener('load', onWindowLoad);
	}
};

if (document.readyState !== 'loading') {
	vGlobal();
} else {
	document.addEventListener('DOMContentLoaded', vGlobal);
}
